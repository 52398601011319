import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"300","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","color":_vm.color.nombre,"dark":_vm.color.dark,"flat":""}},[_c('strong',[_vm._v(_vm._s(_vm.codigoMateria))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cerrar')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-book")])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.nombreMateria))])],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-account-circle")])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.profesor))])],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-alarm")])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.dia)+" "+_vm._s(_vm.hora)+":00")])],1),(_vm.aula)?_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-office-building")])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.aula.codigo)+" - "+_vm._s(_vm.aula.nombre))])],1):_vm._e(),(_vm.virtual)?_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-laptop")])],1),_c(VListItemTitle,[_vm._v("Virtual")])],1):_vm._e()],1)],1)],1)],1),(_vm.puedeAdministrar && _vm.eliminar)?_c(VDivider,{staticClass:"mx-3"}):_vm._e(),(_vm.puedeAdministrar && _vm.eliminar)?_c(VCardActions,[(_vm.puedeAdministrar)?_c(VBtn,{attrs:{"color":"red","outlined":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.eliminarHorario()}}},[_vm._v("Eliminar")]):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }