import {
    deleteServerRequest,
} from '../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function eliminarHorarioGrupo(idHorario) {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/oferta/horario/${idHorario}`;
    const config = { headers: { token: sessionToken } }
    return await deleteServerRequest(url, config);
}