import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"secondary","dark":"","flat":"","dense":""}},[_c(VSpacer),_vm._v(" Horarios "),_c(VSpacer)],1),_c('filtros',{on:{"cambio-loading":function (value) { return _vm.loading = value; },"cambio-selectores":function (e) { return _vm.cambioFiltros(e); }}}),_c(VRow,{staticClass:"mx-1"},[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},_vm._l((15),function(row){return _c(VRow,{key:("sktn-row-" + row)},_vm._l((6),function(col){return _c(VCol,{key:("sktn-col-" + col),attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"card-heading"}})],1)}),1)}),1),_c('horarioGrid',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticStyle:{"padding-bottom":"20px"},attrs:{"horaInicio":7,"horaFin":21,"horario":_vm.horario,"horarioEscuela":_vm.horarioEscuela},scopedSlots:_vm._u([{key:"horarioCelda",fn:function(ref){
var data = ref.data;
var celda = ref.celda;
return [(data.free)?_c('div',{staticClass:"cell-span",on:{"dblclick":function($event){return _vm.mostrarBlockHorarioDialog(celda.dia, celda.hora, false)}}}):_vm._e(),(data.horario)?_c('celdaMateria',{attrs:{"tipo":"usuario","cicloData":_vm.cicloData,"horario":data.horarioCelda},on:{"mostrarInformacion":function($event){return _vm.mostrarInformacionHorario($event, celda)}}}):_vm._e()]}}])}),_c('tablaClases',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"clases":_vm.clases,"horarios":_vm.horario}})],1)],1),_c('infoHorarioDialog',{attrs:{"mostrar":_vm.informacionHorario.mostrar,"codigoMateria":_vm.informacionHorario.codigoMateria,"nombreMateria":_vm.informacionHorario.nombreMateria,"profesor":_vm.informacionHorario.profesor,"aula":_vm.informacionHorario.aula,"dia":_vm.informacionHorario.dia,"hora":_vm.informacionHorario.hora,"color":_vm.informacionHorario.color},on:{"cerrar":function($event){_vm.informacionHorario.mostrar = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }