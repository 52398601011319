<template>
  <div>
    <v-badge
      v-if="horario.length > 1"
      bordered
      :color="cruceHorario.color"
      :icon="cruceHorario.icon"
      overlap
      left
    >
      <v-btn-toggle dense>
        <v-btn
          v-for="horario in horario"
          :key="horario._id"
          :color="horario.color.nombre"
          :dark="horario.color.dark"
          @click="$emit('mostrarInformacion', horario)"
        >
          <v-icon v-if="horario.virtual" small style="margin-right: 5px"
            >mdi-laptop</v-icon
          >
          <v-icon v-if="horario.aula" small style="margin-right: 5px"
            >mdi-office-building</v-icon
          >
          {{ horario.materia.codigo }}
        </v-btn>
      </v-btn-toggle>
    </v-badge>
    <v-btn
      v-else
      v-for="horario in horario"
      :key="horario._id"
      :color="horario.color.nombre"
      :dark="horario.color.dark"
      block
      @click="$emit('mostrarInformacion', horario)"
    >
      <v-icon v-if="horario.virtual" small style="margin-right: 5px"
        >mdi-laptop</v-icon
      >
      <v-icon v-if="horario.aula" small style="margin-right: 5px"
        >mdi-office-building</v-icon
      >
      {{ horario.materia.codigo }}
    </v-btn>
  </div>
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
// import { cargarHorarioProfesor } from "../horarios.service";

export default {
  name: "celdaMateria",
  props: {
    tipo: String,
    horario: Array,
    cicloData: Object,
  },
  components: {},
  computed: {
    ...mapGetters(["sessionToken"]),
    cruceHorario() {
      let data = {
        color: "green",
        icon: "mdi-shuffle-disabled",
      };
      if (this.horario.length > 1) {
        const totalSemanas = this.horario.reduce(
          (accumulator, horario) => accumulator + horario.materia.duracion,
          0
        );
        if (totalSemanas > this.cicloData.semanas) {
          data.color = "red";
          data.icon = "mdi-shuffle-variant";
        }
      }
      return data;
    },
  },
  data: () => ({
    loading: true,
    blockIcon: {},
    btnSelected: null,
  }),
  mounted() {},
  methods: {
    modificarBloqueo() {},
  },
};
</script>

<style scoped>
.chip-blocked {
  width: 100%;
}
.soloLectura {
  opacity: 50%;
}
</style>
