<template>
  <v-container>
    <v-row v-if="sesionPadre">
      <v-col cols="6">
        <v-select
          :items="misHorarios"
          v-model="horarioUsuario"
          label="Mis horarios"
          :loading="loading"
          item-text="nombre"
          item-value="id"
          outlined
          hide-details
          dense
          @change="cargarEscuelas()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
          <v-select
            :items="escuelas"
            v-model="escuela"
            label="Escuela"
            :loading="loading"
            item-text="nombre"
            item-value="_id"
            outlined
            hide-details
            dense
            @change="cambioEscuela()"
          />
      </v-col>
      <v-col cols="4">
        <v-select
          :items="ciclos"
          v-model="ciclo"
          label="Ciclo"
          :loading="loading"
          item-text="nombre"
          item-value="_id"
          outlined
          hide-details
          dense
          @change="cambioEnSelectores()"
        >
          <template v-slot:selection="{ item }">
            <v-icon v-on="on" v-if="item.actual" small color="orange">
              mdi-star
            </v-icon>
            <v-icon v-on="on" v-else small color="grey">
              mdi-star-outline
            </v-icon>
            {{ item.nombre }}
          </template>
          <template v-slot:item="{ item }"><v-icon v-on="on" v-if="item.actual" small color="orange">
              mdi-star
            </v-icon>
            <v-icon v-on="on" v-else small color="grey">
              mdi-star-outline
            </v-icon>
            {{ item.nombre }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="4">
          <v-select
            v-if="this.tipos.length > 1"
            :items="tipos"
            v-model="tipo"
            label="Tipo Usuario"
            :loading="loading"
            outlined
            hide-details
            dense
            @change="cambioEnSelectores()"
          />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from "vuex";
  import { getCiclos, obtenerEscuelas } from "./horarios.service";
  import { getInvitacionesService } from '../globals/template/controlParental/controlParental.service';
  export default {
    name: "filtrosView",
    props: {
    },
    components: {
    },
    computed: {
      ...mapGetters(["role", "sessionToken", "apps", "tipoSesion", "userId"]),
    },
    created() {
      if (this.sessionToken) this.cargarHijos();
    },
    watch: {
      '$store.getters.tipoSesion':function(){
        this.sesionPadre = this.tipoSesion.includes('padre');
      },
      sessionToken: function () {
        this.cargarHijos();
      },
    },
    data() {
      return {
        loading: false,
        on: null,
        ciclo: "",
        ciclos: [],
        escuela: "",
        tipos: [],
        tipo: "alumno",
        sesionPadre: false,
        misHorarios: [],
        horarioUsuario: null,
        escuelas: [],
      };
    },
    mounted() {
      this.tipos = this.tipoSesion.filter( tipo => tipo === 'alumno' || tipo === 'profesor');
      this.sesionPadre = this.tipoSesion.includes('padre');
      if (this.sesionPadre) this.cargarHijos();
      if(this.tipos.length > 0) this.tipo = this.tipos[0];
      if(this.escuelas.length > 0) {
        this.escuela = this.escuelas[0]._id;
        this.cargarCiclos();
      }
    },
    methods: {
      async cargarHijos() {
        try {
          this.loading = true;
          const serverResponse = await getInvitacionesService();
          this.loading = false;
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else {
            this.hijos = serverResponse.invitaciones;
            this.misHorarios = [];
            this.misHorarios.push({id: this.userId, nombre: 'Mis horarios'})
            this.horarioUsuario = this.misHorarios[0].id;
            if(this.hijos.length > 0){
              this.hijos.map( h => {this.misHorarios.push({id: h.hijo._id, nombre: h.hijo.nombreCompleto})})
            }
            this.cargarEscuelas();
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      },
      async cargarEscuelas() {
        try {
          this.loading = true;
          const data = {idUsuario: this.horarioUsuario}
          const serverResponse = await obtenerEscuelas(data);
          this.loading = false;
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else {
            if(serverResponse.escuelas.length > 0){
              this.escuelas = serverResponse.escuelas.map( e => ({ _id: e._id, nombre: e.nombreCorto }));
              this.escuela = this.escuelas[0]._id;
              this.cargarCiclos();
            }else{
              this.escuelas = [];
              this.ciclos = [];
              this.ciclo = "";
              this.escuela = "";
              this.cambioEnSelectores();
            }
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      },
      async cargarCiclos() {
        try {
          this.loading = true;
          this.$emit("cambio-loading", this.loading);
          const sr = await getCiclos(this.escuela);
          this.loading = false;
          this.$emit("cambio-loading", this.loading);
          if (!sr.ok) {
            this.$systemErrorMessage(sr.mensaje);
          } else {
            this.ciclos = sr.ciclos;
            if (this.ciclos.length > 0 ) this.ciclo = this.ciclos[0]._id;
            this.cambioEnSelectores();
          }
        } catch (error) {
          this.loading = false;
          this.$emit("cambio-loading", this.loading);
          if (!error.handdled) this.$appErrorMessage();
        }
      },
      cambioEscuela(){
        this.cargarCiclos();
        this.cambioEnSelectores();
      },
      cambioEnSelectores() {
        const filtros = {
          escuela: this.escuela,
          ciclo: this.ciclo,
          tipo: this.tipo,
          horarioUsuario: this.horarioUsuario,
        }
        this.$emit("cambio-selectores", filtros);
      },
    },
  };
</script>
<style></style>