<template>
  <datatable
    :datos="clasesLocal"
    :loading="loading"
    :page="1"
    :headers="headers"
    :pagination="pagination"
    :totalItems="clases.length"
    hidePagination
    hideSearch
  >
    <template #item-codigoMateria="{ item }">
      <v-chip :color="item.colorCodigo" :dark="item.darkColor" small label>{{
        item.codigoMateria
      }}</v-chip>
    </template>

    <template #item-hrsSemana="{ item }">
      <strong>
        <span v-if="item.hrsSemana !== item.hrsAsignadas" style="color:#F00">{{
          item.hrsAsignadas
        }}</span>
        <span v-else>{{ item.hrsAsignadas }}</span>
        /{{ item.hrsSemana }}
      </strong>
    </template>
  </datatable>
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import datatable from "../globals/template/widgets/datatable.vue";

export default {
  name: "tablaClasesProfesor",
  props: {
    clases: Array,
    horarios: Array
  },
  components: { datatable },
  watch: {},
  computed: {
    ...mapGetters(["sessionToken"]),
    clasesLocal() {
      return this.clases.map((clase) => {
        let grupo = '';
        if (clase.hasOwnProperty('grado') && clase.hasOwnProperty('grupo') && clase.hasOwnProperty('turno'))
          grupo = `${clase.grado.numero}${clase.grupo.letra} ${clase.turno.nombre}`;
        return {
          idClase: clase._id,
          claveClases: clase.clave,
          codigoMateria: clase.materia.codigo,
          colorCodigo: clase.color.nombre,
          darkColor: clase.color.dark,
          materia: clase.materia.nombre,
          carrera: clase.carrera.nombre,
          hrsSemana: clase.materia.hrsSemana,
          hrsAsignadas: this.horarios.filter(x=>x.clase === clase._id).length,
          grupo,
          alumnos: clase.alumnos || 0,
        };
      });
    },
  },
  async mounted() {
  },
  data: () => ({
    loading: false,
    pagination: {
      itemsPerPage: 50,
    },
    headers: [
      {
        text: "Clave",
        value: "claveClases",
        sortable: false,
        filterable: false,
        width: "75px",
      },
      {
        text: "Código",
        value: "codigoMateria",
        sortable: false,
        filterable: false,
        width: "75px",
      },
      {
        text: "Materia",
        value: "materia",
      },
      {
        text: "Carrera",
        value: "carrera",
      },
      {
        text: "Grupo",
        value: "grupo",
      },
      {
        text: "Hrs/Sem",
        value: "hrsSemana",
      },
      {
        text: "Alumnos",
        value: "alumnos",
      },
    ],
  }),
  methods: {

  },
};
</script>

<style></style>
