import { getServerRequest, postServerRequest } from '../globals/services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function getCiclos(idEscuela) {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/horarios/ciclos/${idEscuela}`;
    const config = { headers: { token: sessionToken } };
    return await getServerRequest(url, config);
}

export const postHorarios = async(httpURL, config, data) => {
    const url = `${httpURL}/horarios/horario`;
    return await postServerRequest(url, data, config);
}

export async function cargarHorario({ idEscuela, idCiclo, tipo, horarioUsuario }) {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/horarios/${idEscuela}/${idCiclo}/${tipo}`;
    const config = { headers: { token: sessionToken } }
    return await postServerRequest(url, {horarioUsuario}, config);
}

export const obtenerEscuelas = async(data) => {
    const sessionToken = store.getters.sessionToken;
    const config = { headers: { token: sessionToken } }
    const url = `${httpURL}/horarios/escuelas/usuario`;
    return await postServerRequest(url, data, config);
}