<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat dense class="text-h6">
        <v-spacer></v-spacer>
        Horarios
        <v-spacer></v-spacer>
      </v-toolbar>
      <filtros
        @cambio-loading="(value) => loading = value"
        @cambio-selectores="(e) => cambioFiltros(e)"
      />
      <v-row class="mx-1">
        <v-col cols="12">
          <div v-show="loading">
            <v-row v-for="row in 15" :key="`sktn-row-${row}`">
              <v-col v-for="col in 6" :key="`sktn-col-${col}`" cols="2">
                <v-skeleton-loader type="card-heading"></v-skeleton-loader>
              </v-col>
            </v-row>
          </div>
          <horarioGrid
            v-show="!loading"
            :horaInicio="7"
            :horaFin="21"
            :horario="horario"
            :horarioEscuela="horarioEscuela"
            style="padding-bottom: 20px;"
          >
            <template #horarioCelda="{data, celda}">
              <div
                v-if="data.free"
                class="cell-span"
                @dblclick="mostrarBlockHorarioDialog(celda.dia, celda.hora, false)"
              ></div>
              <celdaMateria
                v-if="data.horario"
                tipo="usuario"
                :cicloData="cicloData"
                :horario="data.horarioCelda"
                @mostrarInformacion="mostrarInformacionHorario($event, celda)"
              />
            </template>
          </horarioGrid>

          <tablaClases v-show="!loading" :clases="clases" :horarios="horario"/>
        </v-col>
      </v-row>

      <infoHorarioDialog
        :mostrar="informacionHorario.mostrar"
        :codigoMateria="informacionHorario.codigoMateria"
        :nombreMateria="informacionHorario.nombreMateria"
        :profesor="informacionHorario.profesor"
        :aula="informacionHorario.aula"
        :dia="informacionHorario.dia"
        :hora="informacionHorario.hora"
        :color="informacionHorario.color"
        @cerrar="informacionHorario.mostrar = false"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import filtros from "./filtros.vue";
import horarioGrid from "../globals/template/horarios/horarioGrid.vue";
import celdaMateria from "../globals/template/horarios/celdaMateria.vue";
import infoHorarioDialog from "../globals/template/horarios/infoHorarioDialog.vue";
import tablaClases from "./tablaClases";
import { cargarHorario } from "./horarios.service";

export default {
  name: "HorariosVuew",
  components: {
    filtros,
    horarioGrid,
    celdaMateria,
    tablaClases,
    infoHorarioDialog,
  },
  computed: {
    ...mapGetters(["role"]),
  },
  watch: {

  },
  data() {
    return {
      loading: false,
      horario: [],
      clases: [],
      idEscuela: null,
      horarioUsuario: null,
      idCiclo: null,
      tipo: null,
      cicloData: {},
      blockHorarioData: {
        mostrar: false,
        dia: null,
        hora: null,
        modificar: false,
        data: {},
      },
      informacionHorario: {
        mostrar: false,
        color: {},
        codigoMateria: null,
        nombreMateria: null,
        profesor: null,
        aula: null,
        dia: null,
        hora: null,
      },
      horarioEscuela: null,
    };
  },
  mounted() {
  },
  methods: {
    cambioFiltros(filtros) {
      this.idEscuela = filtros.escuela;
      this.idCiclo = filtros.ciclo;
      this.tipo = filtros.tipo;
      this.horarioUsuario = filtros.horarioUsuario;
      this.cargarHorario();
    },
    async cargarHorario() {
      if (!this.idEscuela || !this.idCiclo){
        this.clases = [];
        this.horario = [];
        this.aulas = [];
        this.cicloData = {};
        this.horarioEscuela = null;
      } else {
        try {
          this.loading = true;
          const filtro = {
            idEscuela: this.idEscuela,
            idCiclo: this.idCiclo,
            tipo: this.tipo,
            horarioUsuario: this.horarioUsuario,
          };
          const serverResponse = await cargarHorario(filtro);
          this.loading = false;
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else {
            this.clases = serverResponse.clases;
            this.horario = serverResponse.horario;
            this.aulas = serverResponse.aulas;
            this.cicloData = serverResponse.ciclo;
            this.horarioEscuela = serverResponse.horarioPersonalizado;
            this.populateHorario();
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
    populateHorario() {
      this.horario.forEach((hor) => {
        const oferta = this.clases.find((x) => x._id === hor.clase);
        hor.materia = oferta.materia;
        hor.color = oferta.color;
        hor.profesor = this.usuario;
        if (hor.aula)
          hor.aula = this.aulas.find((x) => x._id === hor.aula);
      });
    },
    mostrarInformacionHorario(horario, celda) {
      this.informacionHorario.codigoMateria = horario.materia.codigo;
      this.informacionHorario.nombreMateria = horario.materia.nombre;
      this.informacionHorario.profesor = horario.profesor
        ? horario.profesor.nombreCompleto
        : "Profesor no asignado";
      this.informacionHorario.aula = horario.aula;
      this.informacionHorario.dia = celda.dia.nombre;
      this.informacionHorario.hora = horario.hora;
      this.informacionHorario.color = horario.color;
      this.informacionHorario.mostrar = true;
    },
  },
};
</script>

<style></style>