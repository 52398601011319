<template>
  <div>
    <v-simple-table>
      <tr>
        <th class="cabecera"><div class="subCabecera">Hora</div></th>
        <th v-for="dia in dias" :key="dia.letra" class="cabecera">
          <div class="subCabecera">{{ dia.nombre }}</div>
        </th>
      </tr>

      <tr v-for="hora in horas" :key="`tr-hora-${hora.numero}`">
        <td class="cabecera">
          <div class="hora">{{ hora.texto }}</div>
        </td>
        <td
          v-for="dia of dias"
          :key="`td-${dia.letra}${hora.numero}`"
          class="cabecera"
        >
          <div class="horarioCell">
            <slot
              name="horarioCelda"
              :celda="{ dia, hora: hora.numero }"
              :data="cellsData[`${dia.letra}${hora.numero}`]"
            >
            </slot>
          </div>
        </td>
      </tr>
    </v-simple-table>
  </div>
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  props: {
    horaInicio: Number,
    horaFin: Number,
    blocks: { type: Array, default: () => [] },
    horario: { type: Array, default: () => [] },
    horarioEscuela: { type: Object, default: () => null}
  },
  components: {},
  computed: {
    ...mapGetters(["sessionToken","horarioPersonalizado", "horasPersonalizadas"]),
    cellsData() {
      let data = {};
      for (const dia of this.dias)
        for (let hora = this.horaInicio; hora <= this.hFin; hora++) {
          // Serach por schedule
          const horarioCelda = this.horario.filter(
            (x) => x.dia === dia.letra && x.hora === hora
          );
          if (horarioCelda.length > 0) {
            data[`${dia.letra}${hora}`] = { horario: true, horarioCelda };
            continue;
          }
          // Search for blocks
          const block = this.blocks.find(
            (x) => x.dia === dia.letra && x.hora === hora
          );
          if (block) {
            data[`${dia.letra}${hora}`] = { blocked: true, blockData: block };
            continue;
          }
          data[`${dia.letra}${hora}`] = { free: true };
        }
      return data;
    },
  },
  watch: {
    'horarioEscuela':function(val){
      const horas = val != null ? val.horas : {};
      this.horas = [];
      this.cargarHoras(horas);
    }
  },
  data: () => ({
    loading: false,
    loadingBtn: [],
    dias: [
      { letra: "L", nombre: "Lunes" },
      { letra: "M", nombre: "Martes" },
      { letra: "I", nombre: "Miércoles" },
      { letra: "J", nombre: "Jueves" },
      { letra: "V", nombre: "Viernes" },
      { letra: "S", nombre: "Sábado" },
      { letra: "D", nombre: "Domingo" },
    ],
    horas: [],
    hFin: 0,
  }),
  mounted() {
    this.cargarHoras({});
  },
  methods: {
    cargarHoras(horas){
      const horasTexto = [];
      let horaFinPersonalizado = 0;
      if(Object.keys(horas).length > 0){
        let inicio = this.horaInicio;
        for (let key in horas) {
          const hora = horas[key];
          horasTexto.push({ numero: inicio, texto: `${hora.inicio} - ${hora.fin}` });
          inicio ++;
          horaFinPersonalizado = inicio;
        };
      }
      this.hFin = Object.keys(horas).length > 0 ? horaFinPersonalizado : this.horaFin;
      for (let i = this.horaInicio; i < this.hFin; i++) {
        const personalizado = horasTexto.find( h => h.numero === i);
        const texto = Object.keys(horas).length > 0 ? personalizado.texto : `${i}:00 - ${i + 1}:00`;
        this.horas.push({ numero: i, texto });
      }
    },
  },
};
</script>

<style>
.cabecera {
  height: 40px !important;
  width: 12.5%;
  background: white;
  align-content: center !important;
  text-align: center !important;
}
.subCabecera {
  height: 95%;
  background: #0d3863;
  color: white;
  text-align: center !important;
  vertical-align: middle !important;
  border-radius: 5px;
  padding-top: 7px;
  margin-left: 2px;
  margin-right: 2px;
}
.hora {
  height: 95%;
  background: #3c8dbc;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center !important;
  vertical-align: middle !important;
  border-radius: 5px;
  padding-top: 7px;
  margin-left: 2px;
  margin-right: 2px;
}
.horarioCell {
  height: 95%;
  background: #f4f4f4;
  text-align: center !important;
  vertical-align: middle !important;
  border-radius: 5px;
  padding-top: 2px;
  margin-left: 2px;
  margin-right: 2px;
}
.btnCell {
  height: 95% !important;
}
.drag {
  display: inline-block;
}
.allowed {
  background-color: #e8f5e9 !important;
  width: 90%;
}
.not-allowed {
  background-color: #ef9a9a !important;
  width: 90%;
}
.block-1 {
  background-color: #ffecb3 !important;
  width: 90%;
}
.horarioChip {
  width: 95%;
  padding-left: 35%;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.2;
}
</style>
