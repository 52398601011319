<template>
  <v-dialog v-model="mostrar" width="300" persistent>
    <v-card>
      <v-toolbar dense :color="color.nombre" :dark="color.dark" flat
        ><strong>{{ codigoMateria }}</strong>
        <v-spacer></v-spacer>
        <v-btn icon :disabled="loading" @click="$emit('cerrar')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-list dense>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary">mdi-book</v-icon></v-list-item-icon
                >
                <v-list-item-title>{{ nombreMateria }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-account-circle</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-title>{{ profesor }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary">mdi-alarm</v-icon></v-list-item-icon
                >
                <v-list-item-title>{{ dia }} {{ hora }}:00</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="aula">
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-office-building</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-title
                  >{{ aula.codigo }} - {{ aula.nombre }}</v-list-item-title
                >
              </v-list-item>
              <v-list-item v-if="virtual">
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-laptop</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-title
                  >Virtual</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
      <v-divider v-if="puedeAdministrar && eliminar" class="mx-3"></v-divider>
      <v-card-actions v-if="puedeAdministrar && eliminar">
        <v-btn
          v-if="puedeAdministrar"
          color="red"
          outlined
          small
          :loading="loading"
          @click="eliminarHorario()"
          >Eliminar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { eliminarHorarioGrupo } from "./horarios.service";

export default {
  name: "infoHorarioDialog",
  props: {
    mostrar: Boolean,
    eliminar: { type: Boolean, default: false },
    color: Object,
    codigoMateria: String || null,
    nombreMateria: String || null,
    profesor: String || null,
    aula: Object || null,
    virtual: Boolean || false,
    dia: String || null,
    hora: Number || null,
    idHorario: String,
    puedeAdministrar: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async eliminarHorario() {
      try {
        this.loading = true;
        const serverResponse = await eliminarHorarioGrupo(this.idHorario);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("horarioEliminado", this.idHorario);
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
